import {useTranslation} from 'next-i18next';
import React, {useEffect, useRef, useState} from 'react';

import {LoadingInfo, StyledLoader} from './styles';

export type LoaderProps = {
    size?: number;
    color?: string;
    bg?: string;
    withInfo?: boolean;
};

export const Loader = (props: LoaderProps) => {
    const {t} = useTranslation('common-download');

    const [infoIndex, setInfoIndex] = useState(0);
    const intervalRef = useRef<number | null>(null);

    const changeInfo = () => {
        setInfoIndex((val) => {
            if (val == 1 && intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            return ++val;
        });
    };

    useEffect(() => {
        if (props.withInfo) {
            intervalRef.current = window.setInterval(changeInfo, 1500);
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);

    return !props.withInfo ? (
        <StyledLoader {...props} />
    ) : (
        <>
            <StyledLoader {...props} />
            <LoadingInfo color={props.color}>
                {t('loader', {returnObjects: true})[infoIndex]}
            </LoadingInfo>
        </>
    );
};
