import styled from 'styled-components';

import Button from '~components/download/atoms/Button';
import {above} from '~config/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    opacity: 0.8;

    p {
        font-size: 16px;
        margin: 0;
    }

    ${above.tablet} {
        max-width: 327px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    padding: 16px 24px;
    letter-spacing: 0.02em;
    height: 50px;

    svg {
        margin-right: 8px;
    }
`;
