import React from 'react';
import dynamic from 'next/dynamic';
import {useScopeTranslation} from '@everdome_io/next-js-helper';

import {Modal, ModalProps} from '~components/download/atoms/Modal';
import {Portals} from '~consts/portals';

const ConnectWalletButtons = dynamic(
    () => import('~components/download/atoms/ConnectWalletButtons'),
    {
        ssr: false,
    },
);

type ConnectWalletModalProps = Pick<ModalProps, 'isOpen' | 'close'>;
const ConnectWalletModal = (props: ConnectWalletModalProps) => {
    const t = useScopeTranslation('game-download');

    return (
        <Modal
            rootId={Portals.CONNECT_WALLET}
            width={416}
            height={287}
            title={t('connectWallet')}
            {...props}
        >
            <ConnectWalletButtons />
        </Modal>
    );
};

export default ConnectWalletModal;
