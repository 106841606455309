import React, {ReactNode, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

import CloseIcon from '~assets/download/icons/close.svg';
import {Loader} from '~components/download/atoms/Loader';
import {H5} from '~components/download/atoms/Text';

import {
    CloseButton,
    LoaderContainer,
    ModalContainer,
    ModalOverlay,
    TopRow,
} from './styles';

export type ModalProps = {
    isOpen: boolean;
    width?: number;
    height?: number;
    title?: string;
    close?: () => void;
    children?: ReactNode;
};

const ModalRoot = ({isOpen, width, height, children, title, close}: ModalProps) => {
    return (
        <ModalOverlay isOpen={isOpen}>
            {isOpen && (
                <ModalContainer width={width} height={height}>
                    <TopRow>
                        <H5 as="h2">{title || '\u00A0'}</H5>
                        {close && (
                            <CloseButton onClick={close}>
                                <CloseIcon />
                            </CloseButton>
                        )}
                    </TopRow>
                    {children ? (
                        children
                    ) : (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    )}
                </ModalContainer>
            )}
        </ModalOverlay>
    );
};

export const Modal = ({rootId, ...props}: ModalProps & {rootId: string}) => {
    const [root, setRoot] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setRoot(document.getElementById(rootId));
    }, [rootId]);

    return root === null ? null : createPortal(<ModalRoot {...props} />, root);
};
