import React, {PropsWithChildren} from 'react';
import {Mainnet} from '@usedapp/core';

import {Portals} from '~consts/portals';
import {SwitchNetworkModal} from '~components/download/molecules/SwitchNetworkModal';
import {SeoQuery} from '~types/graphql/SeoQuery';
import {GlobalStyles} from '~components/download/styles/global';

import {HTMLHead} from './HTMLHead';
import {Container} from './styles';

type LayoutProps = PropsWithChildren<{
    className?: string;
    seo?: SeoQuery;
}>;

const Layout: React.FC<LayoutProps> = ({seo, children, className}) => {
    return (
        <>
            <HTMLHead seo={seo} />
            <GlobalStyles />
            <div id={Portals.SWITCH_NETWORK} />
            <div id={Portals.CONNECT_WALLET} />
            <div id={Portals.HARDWARE_REQUIREMENTS} />
            <Container className={className}>{children}</Container>
            <SwitchNetworkModal chainId={Mainnet.chainId} />
        </>
    );
};

export default Layout;
