import React, {PropsWithChildren, useMemo} from 'react';
import Head from 'next/head';
import {useTranslation} from 'react-i18next';

import defaultPoster from '~assets/download/images/poster/poster.png';
import {SeoQuery} from '~types/graphql/SeoQuery';

type HTMLHeadProperties = PropsWithChildren<{
    seo?: SeoQuery;
}>;

export const HTMLHead: React.FC<HTMLHeadProperties> = ({seo}) => {
    const {t} = useTranslation('game-download');

    const {pageTitle, pageDescription, pagePoster} = useMemo(() => {
        return {
            pageTitle: seo?.title || t('appName'),
            pageDescription: seo?.description || t('appDescription'),
            pagePoster: seo?.poster?.data.attributes.url || defaultPoster.src,
        };
    }, [seo]);

    return (
        <Head>
            <meta charSet="utf8" />
            <title>{pageTitle}</title>
            <link rel="shortcut icon" href="/favicon.svg" type="image/x-icon" />

            <meta name="referrer" content="origin" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            {pageDescription && (
                <>
                    <meta name="description" content={pageDescription} />

                    <meta property="og:description" content={pageDescription} />
                </>
            )}

            <meta property="og:title" content={pageTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={pagePoster} />
            <link rel="preconnect" href="https://use.typekit.net" />
            {/* eslint-disable-next-line @next/next/no-page-custom-font */}
            <link
                href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap"
                rel="stylesheet"
            />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="black-translucent"
            />
        </Head>
    );
};
