import React, {ReactNode} from 'react';

import {Loader} from '~components/download/atoms/Loader';

import {LoaderWrapper, StyledButton} from './styles';

type ButtonProps = {
    href?: string;
    variant: 'contained' | 'outlined' | 'text';
    endIcon?: ReactNode;
    text?: string;
    onClick?: () => void;
    children?: ReactNode;
    startIcon?: ReactNode;
    disabled?: boolean;
    className?: string;
    loading?: boolean;
};

const Button = ({
    href,
    text,
    endIcon,
    variant,
    onClick,
    children,
    startIcon,
    disabled,
    className,
    loading,
}: ButtonProps) => {
    return (
        <StyledButton
            disabled={disabled}
            className={className}
            onClick={onClick}
            variant={variant}
        >
            {loading && (
                <LoaderWrapper>
                    <Loader size={20} color={'#fff'} bg={'#000'} />
                </LoaderWrapper>
            )}
            {startIcon && <span>{startIcon}</span>}
            {href && <a href={href} rel="noreferrer" />}
            {text ? text : children}
            {endIcon}
        </StyledButton>
    );
};

export default Button;
