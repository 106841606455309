const envs = {
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    SHARE_FEEDBACK_URL: process.env.NEXT_PUBLIC_SHARE_FEEDBACK_URL,
    IDM_API: process.env.NEXT_PUBLIC_API_ADDRESS,
};
const env = <T = undefined>(
    key: keyof typeof envs,
    defaultValue?: T,
): string | T => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return envs[key] || defaultValue;
};

export default env;
