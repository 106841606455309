import styled, {css} from 'styled-components';

type StyledButtonProps = {
    variant: 'contained' | 'outlined' | 'text';
    disabled?: boolean;
};

export const StyledButton = styled.button<StyledButtonProps>`
    position: relative;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 32px;
    font-weight: 500;
    line-height: 1.75;
    font-size: 14px;
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid transparent;

    svg {
        margin-left: 8px;
    }

    a {
        position: absolute;
        inset: 0 0 0 0;
    }

    ${({variant}) =>
        variant === 'contained'
            ? css`
                  color: white;
                  background-color: black;

                  &:hover {
                      border: 1px solid black;
                      background-color: white;
                      color: black;

                      svg,
                      path {
                          stroke: black;
                      }
                  }

                  &:disabled {
                      background: #afafae;
                  }
              `
            : css`
                  color: black;
                  background: none;
                  border: 1px solid black;

                  &:hover {
                      color: white;
                      background: black;
                  }

                  &:disabled {
                      background: #afafae;
                      color: rgba(0, 0, 0, 0.5);
                      border-color: #afafae;
                  }
              `};
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
