import {toast} from 'react-toastify';
import {ToastOptions} from 'react-toastify/dist/types';

const options: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export function showError(message: string) {
    toast.error(message, options);
}

export function showSuccess(message: string) {
    toast.success(message, options);
}

export function showWarning(message: string) {
    toast.warning(message, options);
}
