import React, {useCallback, useEffect, useState} from 'react';
import {useEthers} from '@usedapp/core';
import {useTranslation} from 'react-i18next';

import {Modal} from '~components/download/atoms/Modal';
import {Portals} from '~consts/portals';
import ETHIcon from '~assets/download/icons/logo_ethereum.svg';

import {Buttons, Container, Content, StyledButton} from './styles';

type ConnectWalletModalProps = {
    chainId: number;
};

export const SwitchNetworkModal = ({chainId}: ConnectWalletModalProps) => {
    const {t} = useTranslation('common-download');
    const {switchNetwork, deactivate} = useEthers();
    const {chainId: currentChainId, account} = useEthers();
    const [isSwitchNetworkModalOpen, setIsSwitchNetworkModalOpen] = useState(false);

    useEffect(() => {
        setIsSwitchNetworkModalOpen(Boolean(account) && currentChainId !== chainId);
    }, [chainId, account, currentChainId]);

    const handleClose = useCallback(() => {
        deactivate();
        localStorage.removeItem('walletconnect');
    }, [deactivate]);

    return (
        <Modal
            rootId={Portals.SWITCH_NETWORK}
            width={416}
            height={274}
            title={t('switchNetwork.title')}
            isOpen={isSwitchNetworkModalOpen}
        >
            <Container>
                <Content>
                    <p>{t('switchNetwork.description')}</p>
                </Content>
                <Buttons>
                    <StyledButton
                        variant="contained"
                        onClick={() => switchNetwork(chainId)}
                    >
                        <ETHIcon />
                        {t('switchNetwork.btnLabel')}
                    </StyledButton>
                    <StyledButton onClick={handleClose} variant="outlined">
                        {t('switchNetwork.btnClose')}
                    </StyledButton>
                </Buttons>
            </Container>
        </Modal>
    );
};
