import styled, {css} from 'styled-components';

import {above} from '~config/breakpoints';

type ModalOverlayProps = {
    isOpen: boolean;
};
export const ModalOverlay = styled.div<ModalOverlayProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color ease-out 0.5s;
    will-change: background-color;

    ${above.tablet} {
        align-items: center;
        justify-content: center;
    }

    ${({isOpen}) =>
        isOpen &&
        css`
            background-color: rgba(0, 0, 0, 0.3);
            pointer-events: all;
        `};
`;

type ModalContainerProps = {
    width?: number;
    height?: number;
};

export const ModalContainer = styled.div<ModalContainerProps>`
    @keyframes scaleInAnimation {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes slideUpAnimation {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    background: #ffffff;
    color: #000;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
    min-width: 100vw;
    min-height: min(${({height}) => height}px, 100vh);
    max-width: 100vw;
    max-height: 100vh;
    position: relative;
    animation-timing-function: ease-out;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: 25px 16px;
    --radius: 24px;
    border-radius: var(--radius) var(--radius) 0 0;
    will-change: transform;
    animation: slideUpAnimation 0.5s;
    animation-fill-mode: backwards;
    pointer-events: all;

    ${above.tablet} {
        padding: 20px 24px 24px;
        border-radius: var(--radius);
        animation: scaleInAnimation 0.3s;
        min-width: min(${({width}) => width}px, 100vw);
    }
`;

export const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    --dash-color: hsl(0, 0%, 70%);
    background: linear-gradient(
        to right,
        transparent 0%,
        transparent 80%,
        var(--dash-color) 80%,
        var(--dash-color) 100%
    );
    background-repeat: repeat-x;
    background-size: 5px 1px;
    background-position: bottom;
`;

export const CloseButton = styled.button`
    background: none;
    appearance: none;
    margin: 0 0 0 32px;
    padding: 0;
    border: none;
    cursor: pointer;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;
