import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background: #fafafa;
    font-family: neue-haas-grotesk-display, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    sans-serif;
  }

  .loginPage * {
    font-family: 'Rajdhani', sans-serif !important;
  }

  .loginPage {
    background: #F6F6F6 !important;
    min-height: 100vh;
  }

  a {
    text-decoration: none;
    color: black;
  }

  html {
    scroll-behavior: smooth;
  }

  :target:before {
    content: '';
    position: relative;
    display: block;
    height: 100px;
    margin: -100px 0 0;
  }
`;
